<!--
 * @Author: 羡国柱
 * @Date: 2023-07-18 14:58:13
 * @LastEditors: 邵澳 1398037353@qq.com
 * @LastEditTime: 2023-08-15 14:24:16
 * @FilePath: \src\components\scrollWrapper\SEduArchives\Sub\SynthesisBoxDialog.vue
 * @Description: 智慧教学 > 综评档案 > 综评成绩 -- 综评成绩 -- 不合格弹窗
-->
<template>
	<div class="synthesis-box-dialog-pages">
		<div v-if="!errorShow" class="right-panel">
			<loading :loadingShow="loadingShow"></loading>
			<expand-filter :formData="formData" @clickBtn="clickBtn" @changeBtnFormType="changeBtnFormType"
				@changeSel="changeSel" @changeCascader="changeCascader"  @treeClick="filterTreeClick">
			</expand-filter>
			<div class="table-paging">
				<table-data ref="table" id="table" :config="table_config" :tableData="tableData"
					:backgroundHeader="backgroundHeader" :headerColor="headerColor"  v-loading="loadingTable">
				</table-data>
				<pagination :total="total" :page.sync="listQuery.pageNum" :limit.sync="listQuery.pageRow" @pagination="getTableList(true)" />
			</div>
			<div style="text-align: right;">
				<el-button @click="resultedClose">关闭</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		// 业务组件
		FilterData,
		TableData,
		DialogWrapper,
		Pagination,
		// 功能组件
		Error,
		Loading,
		// 工具函数
		throttle,
	} from 'common-local';
	import ExpandFilter from '../../Sub/ExpandFilter';
	import { mapState } from 'vuex';
	import { downloadFile ,formatTreeData} from '@/libs/utils';

	export default {
		name: 'SynthesisBoxDialog',
		components: {
			FilterData,
			TableData,
			Pagination,
			DialogWrapper,
			// 功能组件
			Error,
			Loading,
			ExpandFilter
		},
		props: {
			disqualificationObj: {
				type: Object
			}
		},
		data() {
			return {
				errorShow: false,
				loadingShow: false,
				loadingTable: false,
				dialogVisible: false,
				backgroundHeader: '',
				headerColor: '',
				formData: {
					data: [{
						placeholder: '班级',
						type: 'cascader',
						key: 'organId',
						value: '',
						organType: '',
						list: [],
						cascaderProps: {
							label: 'name',
							multiple: false,
							value: 'id',
							checkStrictly: true,
							emitPath: false
						},
						filter(data) {
							return data.organType !== '1';
						}
					},{
						type: 'select',
						label: '',
						value: '',
						placeholder: '选择项目',
						key: 'examId',
						list: [],
					},{
						type: 'input',
						label: '',
						value: '',
						placeholder: '姓名',
						key: 'studentName',
					}, {
						type: 'input',
						label: '',
						value: '',
						placeholder: '唯一号',
						key: 'onlyCode',
					}],
					btnList: [{
						type: 'primary',
						text: '查询',
						fn: 'primary',
						auth: [],
					}, {
						type: 'enquiry',
						text: '重置',
						fn: 'reset',
					},{
						type: 'enquiry',
						text: '导出',
						fn: 'export',
						auth:[]
					}],
					btnFormType: true
				},
				// 表格配置
				table_config: {
					check: false,
					height: 450,
					thead: [{
						label: "方案名称",
						prop: "programmeName",
						type: "popoverText",
						align: "center",
						labelWidth: "200"
					},{
						label: "年级",
						prop: "gradeName",
						type: "popoverText",
						align: "center",
						className: "text-spacing-reduction"
					},{
						label: "班级",
						prop: "className",
						type: "popoverText",
						align: "center",
						className: "text-spacing-reduction"
					},{
						label: "姓名",
						prop: "studentName",
						type: "popoverText",
						align: "center",
						className: "text-spacing-reduction"
					},{
						label: "学号",
						prop: "studyNo",
						type: "popoverText",
						align: "center",
						className: "text-spacing-reduction"
					},{
						label: "唯一号",
						prop: "onlyCode",
						type: "popoverText",
						align: "center",
						labelWidth: "160"
					},{
						label: "项目",
						prop: "examName",
						type: "popoverText",
						align: "center",
						labelWidth: "200",
						className: "text-spacing-reduction"
					},{
						label: "成绩",
						prop: "examScore",
						type: "popoverText",
						align: "center",
						className: "text-spacing-reduction"
					}]
				},
				// 表格数据
				tableData: [],
				// 分页总数
				total: 0,
				// 分页和查询条件
				listQuery: {
					// 页码
					pageNum: 1,
					// 行数
					pageRow: 20,
					// 学校主键
					schoolId: '',
					// 年级主键
					gradeId: '',
					// 班级主键
					classId: '',
					// 方案主键
					programmeId: '',
					// 考试项目主键
					examId: '',
					// 学生姓名
					studentName: '',
					// 唯一号
					onlyCode: '',
					organId: '',
					organType: '',
					jobScope: ['1','2','3']
				}
			}
		},
		watch: {
		   'disqualificationObj.keyId'(newV,oldV) {
				console.log('programmeId ',this.disqualificationObj.programmeId)
				this.init();
		   }
		},
		created() {

		},
		mounted() {
			this.init();
			console.log('programmeId ',this.disqualificationObj.programmeId)
		},
		methods: {
			/**
			 * @Description:年级班级获取数据
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-27 15:43:12
			 */
			handleTreeData() {
				this._fet('/school/schoolOrgan/listAuthByCondition', {
					schoolId: this.$store.state.schoolId,
					jobScope: ['1', '2', '3']
				}).then((res) => {
					if (res.data.code === '200') {
						let index = this.formData.data.findIndex((i) => i.type === 'cascader')
						this.formData.data[index].list = formatTreeData(res.data.data.list, 'id', 'parentOrg')
					} else {
						this.$message.error(res.data.msg)
					}
				});
			},
			/**
			 * @Description: 加载
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-21 14:05:52
			 * handleProjectList 项目列表
			 * handleGradeList 年级列表
			 */
			init() {
				this.formData=this.$options.data.call(this).formData
				this.getTableList();
				this.handleProjectList();
				this.handleGradeList();
				this.handleTreeData();
			},
			/**
			 * @Description: 多重循环查找ID
			 * @DoWhat:
			 * @UseScenarios:
			 * @Attention:
			 * @Author: 陈金伟
			 * @Date: 2023-08-11 14:53:08
			 * @param {*} arr
			 * @param {*} id
			 */
			 searchObjectById(arr, id) {
				for (let i = 0; i < arr.length; i++) {
					if (Array.isArray(arr[i].children)) {    // 判断当前元素是否包含子元素
						const result = this.searchObjectById(arr[i].children, id);    // 递归调用，传入子元素作为新的数组参数
						if (result) {
							return result;    // 如果找到匹配的对象，则返回结果
						}
					} else if (arr[i].id === id) {   // 判断当前元素的ID是否匹配
						return arr[i];    // 返回匹配的对象
					}
				}

				return null;    // 如果没有找到匹配的对象，则返回null
			},
			/**
			 * @Description: 年级班级改变事件
			 * @DoWhat:
			 * @UseScenarios:
			 * @Attention:
			 * @Author: 陈金伟
			 * @Date: 2023-08-11 14:46:33
			 */
			changeCascader(data){
				// let value=this.searchObjectById(data.data.list,data.data.value)
				// console.log(value,'data1')
				// this.formData.data.forEach((item) => {
				//     if (item.key === 'organId') {
				//         item.organType = value.organType;
				//     }
				// })
				
				let node;
				if (data.ref.getCheckedNodes()[0]) {
					node = data.ref.getCheckedNodes()[0].data;
					data.data.organType = node.organType;
					console.log(node,'node');
				} else {
					data.data.organType = '';
				}
				data.ref.dropDownVisible = false;
			},
			/**
			 * @Description: 级联获取值
			 * @DoWhat:
			 * @UseScenarios:
			 * @Attention:
			 * @Author: 羡国柱
			 * @Date: 2023-08-12 18:32:46
			 */
			filterTreeClick(data) {
				this.listQuery.organId = data.data.id
				data.dt.value = data.data.name
				data.selRef.blur()
			},
			/**
			 * @Description: 展开 收起
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-21 14:07:22
			 */
			changeBtnFormType() {
				this.formData.btnFormType = !this.formData.btnFormType;
				this.$nextTick(() => {
					this.tableHeight = document.body.clientHeight - this.$refs.table.$el.offsetTop - 62;
				});
			},
			/**
			 * @Description: 操作按钮
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-21 14:11:18
			 */
			clickBtn(ev) {
				switch (ev.item.fn) {
					case 'primary': // 查询
						this.formData.data.forEach((item) => {
							this.listQuery[item.key] = item.value;
							if(item.key=='organId'){
								this.listQuery['organType']=item.organType
							}
						})
						this.getTableList(0);
						break;
					case 'reset': // 重置
						this.formData.data.forEach((item) => {
							item.value = '';
							this.listQuery[item.key] = '';
						})
						this.listQuery.organId = '';
						this.listQuery.organType = '';
						this.getTableList(0);
						break;
					case 'export': // 导出
						this.export();
						break;
					default:
						break
				}
			},
			/**
			 * @Description: 搜索框change事件
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-21 14:12:18
			 */
			changeSel(data) {
				if (data.key === 'gradeId') {
					this.handleClazzList(data.value)
				}
			},
			/**
			 * @Description: 导出
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-21 14:12:26
			 */
			export() {
				let obj = {}
				let filter = this.formData.data.filter(i => i.value !== '');
				filter.forEach(item => {
					obj[item.key] = item.value;
				})
				obj.programmeId = this.disqualificationObj.programmeId;
				obj.schoolId = this.$store.state.schoolId;
				obj.organType = this.listQuery.organType;
				obj.organId = this.listQuery.organId;
				obj.jobScope = ['1', '2', '3']
				downloadFile({url: '/school/schoolCerScore/notPassExport', form: obj}, () => {
					this.$message.success('导出成功')
				}, () => {

				})
			},
			/**
			 * @Description: 获取项目等级列表
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-21 14:14:38
			 */
			handleProjectList() {
				// type 1项目 2总分
				this._fet(`/school/schoolCerProgramme/info/${this.disqualificationObj.programmeId}`, {
					// schoolId: this.$store.state.schoolId,
					// type: '1',
				}).then((res) => {
					if (res.data.code === '200') {
						res.data.data.examList.forEach((item) => {
							let obj = {
								label: item.examName,
								value: item.examId
							}
							this.formData.data.forEach((item) => {
								if (item.key === 'examId') {
									item.list.push(obj)
								}
							})
						})
					} else if (res.data && res.data.msg) {
						this.$message.error(res.data.msg);
					}
				});
			},
			/**
			 * @Description:获取年级信息列表
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-21 14:15:03
			 */
			handleGradeList() {
				this._fet('/school/schoolOrgan/listByCondition', {
					schoolId: this.$store.state.schoolId,
					organType: '3'
				}).then((res) => {
					if (res.data.code === '200') {
						res.data.data.list.forEach((item) => {
							let obj = {
								value: item.id,
								label: item.name
							}
							this.formData.data.forEach((item) => {
								if (item.key === 'gradeId') {
									item.list.push(obj)
								}
							})
						})
					} else if (res.data && res.data.msg) {
						this.$message.error(res.data.msg);
					}
				});
			},
			/**
			 * @Description:获取班级信息列表
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-21 14:15:15
			 */
			handleClazzList(gradeId) {
				let dataList = [];
				this.formData.data.forEach((item) => {
					if (item.key === 'classId') {
						item.value = '';
						item.label = '';
					}
				})
				this._fet('/school/schoolOrgan/getClassByGradeId', {
					schoolId: this.$store.state.schoolId,
					gradeId: gradeId
				}).then((res) => {
					if (res.data.code === '200') {
						res.data.data.forEach((item) => {
							let obj = {
								value: item.id,
								label: item.name
							}
							dataList.push(obj)
							this.formData.data.forEach((item) => {
								if (item.key === 'classId') {
									item.list = dataList;
								}
							})
						})
					} else if (res.data && res.data.msg) {
						this.$message.error(res.data.msg);
					}
				});
			},
			/**
			 * @Description: 获取分页列表数据
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-21 14:15:25
			 */
			getTableList(isPaging ){
				if (!isPaging) {
					this.listQuery.pageNum = 1;
				}
				this.loadingTable = true;
				this.listQuery.programmeId = this.disqualificationObj.programmeId;
				this.listQuery.schoolId = this.$store.state.schoolId;
				this._fet('/school/schoolCerScore/notPasslist', this.listQuery)
					.then((res) => {
						if (res.data.code === '200') {
							this.total = res.data.data.totalCount;
							this.tableData = res.data.data.list;
						} else if (res.data && res.data.msg) {
							this.$message.error(res.data.msg);
						}
						this.loadingTable = false;
					});
			},
			/**
			 * @Description: 获取列表数据
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-21 14:15:53
			 */
			resultedClose(){
				this.formData=this.$options.data.call(this).formData
				console.log(this.formData)
				this.$emit('change', 'false')
			}
		}
	}

</script>

<style lang="scss" scoped>
</style>
