<!--
 * @Author: 羡国柱
 * @Date: 2023-07-18 14:58:13
 * @LastEditors: 邵澳 1398037353@qq.com
 * @LastEditTime: 2023-08-16 14:51:15
 * @FilePath: \src\components\scrollWrapper\SEduArchives\Sub\SynthesisBoxEdit.vue
 * @Description: 智慧教学 > 综评档案 > 综评成绩 -- 编辑成绩
-->
<template>
	<div class="synthesis-box-edit-pages">
		<table border="1px solid #eff1f8;" cellspacing="0"
			style="border:1px solid #eff1f8; border-collapse:collapse; width:100%; border-radius: 4px;">
			<tr>
				<th colspan="2" >
					<div style="color: #333; font-size: 18px; font-weight:bold; padding: 6px 0;">{{ editObj.studentTableTitle }}</div>
				</th>
			</tr>
			<tr>
				<td>
					<div style="color: #505559; font-size: 14px; ; padding: 6px 0; text-align: center;">
						<span>姓名：</span>
						<span>{{ editObj.studentName }}</span>
					</div>
				</td>
				<td>
					<div style="color: #505559; font-size: 14px; ; padding: 6px 0; text-align: center;">
						<span>唯一号：</span>
						<span>{{ editObj.onlyCode }}</span>
					</div>
				</td>
			</tr>
		</table>
		<div class="student-dialog-box">
			<el-table
				ref="stable" id="stable"
				style="width: 100%" size="small" border
				:data="editObj.tableData" :span-method="objectSpanMethod"
				:header-cell-style="{ background:'#F1F1F1',color:'#2B2F33' }">
				<el-table-column prop="examName" align="center" label="项ㅤ目" show-overflow-tooltip></el-table-column>
				<el-table-column prop="examTScore" align="center" label="成绩合计"></el-table-column>
				<el-table-column align="center" prop="examScore" label="修改成绩">
					<template slot-scope="scope">
						<div class="exam_score-edit">
							<span class="exam-subject-name" :title="scope.row.examSubjectName">{{ scope.row.examSubjectName }}</span>
							<el-form :model="scope.row" :inline-message="true" :rules="rules" ref="ruleForm" class="scoreForm">
								<el-form-item prop="examScore">
									<el-input v-model="scope.row.examScore" size="mini"></el-input>
								</el-form-item>
							</el-form>
						</div>
					</template>
				</el-table-column>
				
				<div slot="empty">
					<p :style="{'marginTop': '23px'}">暂无数据</p>
				</div>
			</el-table>
		</div>
		
		<div style="text-align: right;">
			<el-button @click="resultClose">取消</el-button>
			<el-button type="primary" @click="saveClick">保存</el-button>
		</div>
	</div>
</template>

<script>
	
	import {
		// 业务组件
		FilterData,
		TableData,
		DialogWrapper,
		Pagination,
		// 功能组件
		Error,
		Loading,
		// 工具函数
		throttle,
	} from 'common-local';
	import { mapState } from 'vuex';
	import { downloadFile } from '@/libs/utils';
	import ExpandFilter from '../../Sub/ExpandFilter';
	// 不合格弹窗
	import SynthesisBoxDialog from './SynthesisBoxDialog.vue';
	
	export default {
		name: 'SynthesisBoxEdit',
		components: {
			FilterData,
			TableData,
			Pagination,
			DialogWrapper,
			// 功能组件
			Error,
			Loading,
			ExpandFilter,
			SynthesisBoxDialog
		},
		props: {
			editObj: {
				type: Object
			}
		},
		data() {
			//整数部分最多三位 小数部分最多两位
			let reg = /^-?(0|[1-9]\d{0,2})(\.\d{1,2})?$/;
			//校验修改的成绩
			let checkScore = (rule, value, callback)=>{
				if (value === '') {
					callback('请输入分数');
					return
				}
				if(!reg.test(value)){
					callback('三位整数两位小数');
					return
				}
				callback();
			}
			return {
				reg:/^-?(0|[1-9]\d{0,2})(\.\d{1,2})?$/,
				mergeEditObj: {},
				examIds: [],
				mergeEditArr: ['examName', 'examTScore'],
				rules:{
					examScore: [
						{ validator: checkScore, trigger: ['blur','change'] }
					],
				}
			}
		},
		computed: {
			...mapState({
				permissions: (state) => state.permissions
			})
		},
		watch: {
			'editObj.keyId'(newV,oldV) {
				console.log('this.editObj.tableData', this.editObj.tableData)
				this.init();
			}
		},
		created() {
			
		},
		mounted() {
			this.init();
		},
		methods: {
			/**
			 * @Description: 加载
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-21 17:06:51
			 */
			init() {
				this.getSpanArr(this.editObj.tableData, "examId");
			},
			/**
			 * @Description: 取消
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-21 17:15:02
			 */
			resultClose() {
				this.$emit('change', 'NO')
			},
			/**
			 * @Description: 保存
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-21 17:15:11
			 */
			async saveClick() {
				let obj = {};
				let dataList = this.editObj.tableData;
				let examList = [];
				let courseList = [];
				let flag = true
				for(let j = 0; j < dataList.length; j++) {
					if(!this.reg.test(dataList[j].examScore)){
						flag = false;
						let temp = this.editObj.tableData[j].examScore;
						this.editObj.tableData[j].examScore = '';
						this.$forceUpdate();
						this.$nextTick(()=>{
							this.editObj.tableData[j].examScore = temp;
							this.$forceUpdate();
						})
					}
					courseList.push({
						id: dataList[j].id,
						examScore: dataList[j].examScore,
						examSubjectId: dataList[j].examSubjectId
					})
					examList.push({
						examId: dataList[j].examId,
						courseList: courseList 
					})
				}
				if(!flag){
					return
				}
				// 重复数据去重
				examList = examList.reduce(function(item, next) {
					obj[next.examId] ? '' : obj[next.examId] = true && item.push(next);
					return item;
				}, []);
				
				this._fet('/school/schoolCerScore/changeScore', {
					schoolId: this.$store.state.schoolId,
					programmeId: this.editObj.programmeId,
					onlyCode: this.editObj.onlyCode,
					examList: examList
				}).then((res) => {
					if (res.data.code === '200') {
						this.$message.success('保存成功');
						this.$emit('change', 'YES');
					} else if (res.data && res.data.msg) {
						this.$message.error(res.data.msg);
					}
				});
			},
			checkRules(){
				return new Promise(resolve => {
					this.$refs.ruleForm.validate((valid) => {
						if (valid) {
							resolve(1)
						}else{
							resolve(0)
						}
					});
				})
			},
			getSpanArr(data, groupKey) {
				this.mergeEditArr.forEach((key, index1) => {
					let count = 0; // 用来记录需要合并行的起始位置
					this.mergeEditObj[key] = []; // 记录每一列的合并信息
					let compareKey = groupKey ? groupKey : key
					data.forEach((item, index) => {
						// index == 0表示数据为第一行，直接 push 一个 1
						if(index === 0) {
							this.mergeEditObj[key].push(1); 
						} else {
							// 判断当前行是否与上一行其值相等 如果相等 在 count 记录的位置其值 +1 表示当前行需要合并 并push 一个 0 作为占位
							if(item[compareKey] === data[index - 1][compareKey]) {
								this.mergeEditObj[key][count] += 1;
								this.mergeEditObj[key].push(0);
							} else {
								// 如果当前行和上一行其值不相等 
								count = index; // 记录当前位置 
								this.mergeEditObj[key].push(1); // 重新push 一个 1
							}
						}
					})
				})
			},
			objectSpanMethod({ row, column, rowIndex, columnIndex }) {
				// 判断列的属性
				if(this.mergeEditArr.indexOf(column.property) !== -1) { 
					// 判断其值是不是为0 
					if(this.mergeEditObj[column.property][rowIndex]) { 
						return [this.mergeEditObj[column.property][rowIndex], 1]
					} else {
						// 如果为0则为需要合并的行
						return [0, 0]; 
					}
				}
			}
		}	
	}
	
</script>

<style scoped lang="scss">
	.student-dialog-box {
		min-height: 360px;
		margin: 10px auto;

		/* 鼠标悬浮行 */
		::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
			background-color: unset;
		}
	}
	
	.student-dialog-box .exam_score-edit {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	
	.student-dialog-box .exam_score-edit .exam-subject-name {
		width: 140px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		line-height: 32px;
	}
	
	.student-dialog-box .exam_score-edit /deep/ .el-input {
		width: 100px;
	}

	::v-deep{
		.scoreForm{
			width: 100px;
			.el-form-item{
				margin-bottom: 0 !important;
			}
		}
		.el-table.el-table--small .el-table__body tr{
			min-height: 48px !important;
		}
	}
</style>